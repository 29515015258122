import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const PreviousPage = createContext(null)

export const PreviousPageProvider = ({ children }) => {
  const [previousPage, setPreviousPage] = useState('/')
  const [backToPage, setBackToPage] = useState(null)

  const value = useMemo(() => (
    {
      backToPage,
      previousPage,
      setBackToPage,
      setPreviousPage,
    }
  ), [previousPage, backToPage])

  return (
    <PreviousPage.Provider value={value}>
      {children}
    </PreviousPage.Provider>
  )
}

PreviousPageProvider.propTypes = {
  children: PropTypes.node
}

PreviousPageProvider.defaultProps = {
  children: null
}

export const usePreviousPage = () => useContext(PreviousPage)
