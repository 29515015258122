import { useRoutes } from 'react-router-dom'

import routesConfig from './config/routes'

const Router = () => {
  const routes = useRoutes(routesConfig)

  return routes
}

export default Router
