import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

const AnimatedRoutes = ({ children }) => (
  <AnimatePresence exitBeforeEnter>
    <motion.div
      initial={{ opacity: 0, scale: 0.75 }}
      animate={{ opacity: 1, scale: 1 }}
      // animate={{
      //   scale: [1, 2, 2, 1, 1],
      //   rotate: [0, 0, 270, 270, 0],
      //   borderRadius: ["20%", "20%", "50%", "50%", "20%"],
      // }}
      transition={{ duration: 0.3 }}
      className="AnimatedRoutes"
    >
      {children}
    </motion.div>
  </AnimatePresence>
)

AnimatedRoutes.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AnimatedRoutes
