import React from 'react'

import googlePlayBadge from '../../../assets/images/icons/google-play-badge.png'
import arenaLogo from '../../../assets/images/logo/dzastr-arena-logo-alt.png'
import { ReactComponent as AppStoreBadge } from '../../../assets/images/icons/app-store-badge.svg'
import { deviceChecker } from '../../../helpers/deviceChecker'

import './index.scss'

const ArenaPage = () => {
  // console.log(deviceChecker());

  return (
    <div className="AppPage ArenaPage">
      <a
        // href="https://arena.dzastr.io"
        target="_blank"
        rel="noreferrer"
        className="AppPage__link"
      >
        <img
          src={arenaLogo}
          alt="Arena"
          className="AppPage__logo"
        />
      </a>
      <p>Test your skills and compete against other athletes all around the world.</p>
      <div className="AppPage__downloads">
        {deviceChecker() === 'Android' ? (
          <button>
            <img src={googlePlayBadge} alt="Google Play" />
          </button>
        ) : deviceChecker() === 'iOS' ? (
          <button>
            <AppStoreBadge />
          </button>
        ) : null}
      </div>
    </div>
  )
}

ArenaPage.propTypes = {
}

export default ArenaPage
