// https://codepen.io/bh/pen/JBlCc?editors=1010
// https://codepen.io/bright1/pen/amZmgJ
import React from 'react'

import './index.scss'

const maxAnimationDuration = 30
const maxBubbleSize = 30
const medBubbleSize = 15
const minAnimationDuration = 10
const minBubbleSize = 5
const totalBubbles = 40

const startingBubbleDurations = [
  10, 11, 11, 12, 13, 13, 14,
  15, 15, 15, 17, 18, 19, 19, 19,
]

const Bubble = ({ duration, position }) => {
  const getAnimationDuration = (size) => (
    minAnimationDuration
    + Math.floor(Math.random() * maxAnimationDuration)
    * (size / 4)
  )

  const bubbleSizeFactor = duration < 10 ? medBubbleSize : maxBubbleSize
  const blurSize = Math.floor(Math.random() * 5 + 1)
  const bubblePos = Math.floor(Math.random() * 100 + 1)
  const bubbleSize = minBubbleSize + Math.floor(Math.random() * bubbleSizeFactor + 1)
  const opacity = Math.random() + 0.2

  const animationDuration = duration || getAnimationDuration(bubbleSize)

  return (
    <div
      className='Bubble__container'
      style={{
        MozAnimationDuration: `${animationDuration}s`,
        MozFilter: `blur(${blurSize}px)`,
        WebkitAnimationDuration: `${animationDuration}s`,
        WebkitFilter: `blur(${blurSize}px)`,
        animationDuration: `${animationDuration}s`,
        filter: `blur(${blurSize}px)`,
        left: `${bubblePos}%`,
        opacity: opacity,
      }}
    >
      <div
        className='Bubble'
        style={{
          height: `${bubbleSize}px`,
          width: `${bubbleSize}px`,
        }}
      >
        <div
          className="Bubble__light"
          style={{
            height: `${Math.floor(bubbleSize / 4)}px`,
            width: `${Math.floor(bubbleSize / 4)}px`,
          }}
        />
      </div>
    </div>
  )
}

const Bubbles = ({ children }) => (
  <div className="Bubbles__content">
    <div className="Bubbles">
      {startingBubbleDurations.map((duration) => (
        <Bubble duration={duration} />
      ))}

      {[...Array(totalBubbles)].map(() => (
        <Bubble />
      ))}
    </div>
    <div className="Bubbles__bottom" />
    {children}
  </div>
)

export default Bubbles
