import React from 'react'

import AnimatedRoutes from '../layouts/animated.jsx'

import HomePage from '../../pages/static/Home'
import AboutPage from '../../pages/static/About'
import ContactPage from '../../pages/static/Contact'

import BeaconPage from '../../pages/apps/Beacon'
import ArenaPage from '../../pages/apps/Arena'
import ShopPage from '../../pages/apps/Shop'
import NinetyOrDiePage from "../../pages/apps/NinetyOrDie";

const publicRoutes = [
  {
    children: [
      {
        path: '/',
        element: <AnimatedRoutes><HomePage /></AnimatedRoutes>,
        // element: <HomePage />,
      },
      {
        path: '/about',
        element: <AnimatedRoutes><AboutPage /></AnimatedRoutes>,
        // element: <AboutPage />,
      },
      {
        path: '/contact',
        element: <AnimatedRoutes><ContactPage /></AnimatedRoutes>,
        // element: <ContactPage />,
      },
      {
        path: '/apps',
        children: [
          {
            path: 'arena',
            element: <AnimatedRoutes><ArenaPage /></AnimatedRoutes>,
            // element: <ArenaPage />,
          },
          {
            path: 'beacon',
            element: <AnimatedRoutes><BeaconPage /></AnimatedRoutes>,
            // element: <BeaconPage />,
          },
          {
            path: 'shop',
            element: <AnimatedRoutes><ShopPage /></AnimatedRoutes>,
            // element: <ShopPage />,
          },
          {
            path: '90-or-die',
            element: <AnimatedRoutes><NinetyOrDiePage /></AnimatedRoutes>,
            // element: <ContactPage />,
          },
        ]
      }
    ]
  },
]

export default publicRoutes
