import React from 'react'

import PageWrapper from '../../../components/layouts/PageWrapper'
import PageContent from '../../../components/layouts/PageContent'
import withScrollTop from '../../../hocs/withScrollTop'

import './index.scss'

const ContactPage = () => (
  <PageWrapper name="ContactPage" title="Contact">
    <PageContent>
      <h1>Contact</h1>
    </PageContent>
  </PageWrapper>
)

export default withScrollTop(ContactPage)
