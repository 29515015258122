import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { createRoot } from 'react-dom/client'

import BackButton from './components/BackButton'
import Bubbles from './components/Bubbles'
import Router from './routes/router'
import reportWebVitals from './reportWebVitals'
import { PreviousPageProvider } from './context/previousPage'

import './assets/stylesheets/global.scss'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <>
    <Helmet>
      <title>DZASTR Labs</title>
      <meta name="description" content="DZASTR Labs" />
    </Helmet>
    <PreviousPageProvider>
      <BrowserRouter>
        <Bubbles>
          <Router />
          <BackButton />
        </Bubbles>
      </BrowserRouter>
    </PreviousPageProvider>
  </>
)

reportWebVitals()
