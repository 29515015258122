import React from 'react'

import './index.scss'

const ShopPage = () => (
  <div className=" AppPage ShopPage">
    <p>Coming Soon!</p>
  </div>
)

ShopPage.propTypes = {
}

export default ShopPage
