import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { usePreviousPage } from '../../context/previousPage'
import { ReactComponent as ChevronLeft } from '../../assets/images/icons/chevron-left-solid.svg'

import './index.scss'

const BackButton = () => {
  const { backToPage, setBackToPage, setPreviousPage } = usePreviousPage()
  const location = useLocation()
  const navigate = useNavigate()

  const onEscape = (e) => {
    if (e.key === 'Escape') {
      navigate('/')
    }
  }

  useEffect(() => {
    if (location.pathname.includes('/app')) {
      setBackToPage('Apps')
      document.addEventListener('keydown', onEscape)
    } else {
      setBackToPage(null)
      document.removeEventListener('keydown', onEscape)
    }
    setPreviousPage(location.pathname)

  }, [location, setBackToPage, setPreviousPage])

  return (
    !!backToPage ? (
      <div className="BackButton">
        <Link to="/">
          <ChevronLeft />
          Back to {backToPage}
        </Link>
      </div>
    ) : null
  )
}

BackButton.propTypes = {
}

export default BackButton
