import React, { createContext, useContext, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'

const HeaderTitle = createContext(null)

export const HeaderTitleProvider = ({ children }) => {
  const headerTitleReducer = (headerTitle, action) => {
    switch (action.type) {
      case 'SET_HEADER_TITLE':
        return action.payload
      default:
        throw new Error()
    }
  }

  const [headerTitle, dispatch] = useReducer(headerTitleReducer, null)

  const setHeaderTitle = (title) => dispatch({ type: 'SET_HEADER_TITLE', payload: title })

  const value = useMemo(() => (
    { headerTitle, setHeaderTitle }
  ), [headerTitle])

  return (
    <HeaderTitle.Provider value={value}>
      {children}
    </HeaderTitle.Provider>
  )
}

HeaderTitleProvider.propTypes = {
  children: PropTypes.node
}

HeaderTitleProvider.defaultProps = {
  children: null
}

export const useHeaderTitle = () => useContext(HeaderTitle)
