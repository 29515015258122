import React from 'react'

import NotFoundPage from '../../pages/static/NotFound'

import publicRoutes from './public'

const routesConfig = [
  ...publicRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routesConfig
