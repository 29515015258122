import React from 'react'
import { Link } from 'react-router-dom'

import dzastrImg from '../../../assets/images/logo/dzastr-logo-1.png'
import arenaLogo from '../../../assets/images/logo/arena-logo.png'
import shopLogo from '../../../assets/images/logo/dzastr-coin-green.png'
import withScrollTop from '../../../hocs/withScrollTop'
import { ReactComponent as BeaconLogo } from '../../../assets/images/icons/location-crosshairs-duotone-alt.svg'

import './index.scss'

const buttonStyle = {
  // backgroundColor: '#A1D341',
  // color: 'black',
  backgroundColor: 'transparent',
  borderColor: '#A1D341',
  borderWidth: '2px',
  borderStyle: 'solid',
  color: '#A1D341',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '10px',
  width: '200px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  fontSize: '25px',
}


const HomePage = () => {
  const onClick = () => {
    console.log('90 OR DIE')
  }

  return (
      <div className="HomePage">
        <div className="HomePage__dzastr_logo">
          <img
              src={dzastrImg}
              alt=""
              className="HomePage__logo HomePage__logo__main"
          />
        </div>
        {/*<button style={buttonStyle}>90 OR DIE</button>*/}
        <Link
            to="/apps/90-or-die"
            className="button"
            children={'90 OR DIE'}
          />
        <div className="HomePage__apps">
          <Link
              className="HomePage__app"
              to="/apps/arena"
          >
            <img
                src={arenaLogo}
                alt="Global Virtual Challenge"
                className="HomePage__logo HomePage__app__logo HomePage__logo__arena"
            />
            <h2 className="HomePage__app__title">Arena</h2>
          </Link>
          <Link
              className="HomePage__app"
              to="/apps/shop"
          >
            <img
                src={shopLogo}
                alt="DZASTR Shop"
                className="HomePage__logo HomePage__app__logo HomePage__logo__shop"
            />
            <h2 className="HomePage__app__title">Shop</h2>
          </Link>
          <Link
              className="HomePage__app"
              to="/apps/beacon"
          >
            <BeaconLogo/>
            <h2 className="HomePage__app__title">Beacon</h2>
          </Link>
        </div>
      </div>
  )
}

export default withScrollTop(HomePage)
