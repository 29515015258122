import React from 'react'

import './index.scss'

const BeaconPage = () => (
  <div className="AppPage BeaconPage">
    <p>Coming Soon!</p>
  </div>
)

BeaconPage.propTypes = {
}

export default BeaconPage
