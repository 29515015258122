import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHeaderTitle } from '../../../context/headerTitle'

import './index.scss'

const LAYOUTS = {
  standard: 'standard',
}

const PageWrapper = ({
  children,
  layout,
  name,
  opts,
  title
}) => {
  const { setHeaderTitle } = useHeaderTitle()

  useEffect(() => {
    if (title) setHeaderTitle(title)
  }, [setHeaderTitle, title])

  return (
    <div
      className={classNames(
        'PageWrapper',
        {
          'PageWrapper--no-padding': opts.noPadding,
          'PageWrapper--padding': opts.padding,
          'PageWrapper--adminForm': layout === LAYOUTS.adminForm,
          'PageWrapper--col': layout === LAYOUTS.col,
          'PageWrapper--form': layout === LAYOUTS.form,
          'PageWrapper--center': layout === LAYOUTS.center,
        }
      )}
      id={name}
    >
      { children }
    </div>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(Object.keys(LAYOUTS)),
  name: PropTypes.string.isRequired,
  opts: PropTypes.objectOf(PropTypes.shape),
  title: PropTypes.string,
}

PageWrapper.defaultProps = {
  layout: LAYOUTS.standard,
  opts: {},
  title: null
}

export default PageWrapper
