import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const PageContent = ({ children, name }) => (
  <div className="PageContent" id={name}>
    { children }
  </div>
)

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
}

PageContent.defaultProps = {
  name: '',
}

export default PageContent
