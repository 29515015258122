import React, {useEffect, useRef} from 'react'

import './index.scss'
// import Hls from 'hls.js'
import videojs from "video.js";
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/city/index.css'
import '@videojs/themes/dist/sea/index.css'
import '@videojs/themes/dist/fantasy/index.css'
import '@videojs/themes/dist/forest/index.css'
import 'videojs-sublime-skin/dist/videojs-sublime-skin.min.css'
import poster from './90-or-die.jpg'
import "videojs-hotkeys";


const theme = 'forest'

// const HLSVideoPlayer = ({ src }) => {
//   const videoRef = useRef(null)
//
//
//   useEffect(() => {
//     const video = videoRef.current
//
//     let hls = null
//     if (Hls.isSupported()) {
//       hls = new Hls()
//       hls.loadSource(src)
//       hls.attachMedia(video)
//       hls.on(Hls.Events.MANIFEST_PARSED, () => {
//         video.play()
//       })
//     } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
//       video.src = src
//       video.addEventListener('canplay', () => {
//         video.play()
//       })
//     }
//
//     return () => {
//       if (Hls.isSupported()) {
//         hls.destroy()
//       }
//     }
//   }, [src])
//
//   return <video ref={videoRef} controls width="100%" height="auto" />
// }

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  useEffect(() => {
    const videoElement = videoRef.current

    if (!playerRef.current) {
      playerRef.current = videojs(videoElement, {
        controls: true,
        responsive: true,
        fluid: true,
        poster,
        preload: 'auto',
        sources: [{ src, type: 'video/mp4' }],
        controlBar: {
          playToggle: true,
          volumePanel: { inline: false },
          fullscreenToggle: true,
        },
        plugins: {
          hotkeys: {
            volumeStep: 0.1,
            seekStep: 5,
            enableModifiersForNumbers: false
          },
        },
      })
    } else {
      playerRef.current.src({ src, type: 'video/mp4' })
    }

    // const handleKeyDown = (event) => {
    //   const player = playerRef.current
    //
    //   if (event.key === 'f') {
    //     event.preventDefault()
    //     if (player.isFullscreen()) {
    //       player.exitFullscreen()
    //     } else {
    //       player.requestFullscreen()
    //     }
    //   } else if (event.key === ' ') { // Spacebar for play/pause
    //     event.preventDefault()
    //     if (player.paused()) {
    //       player.play()
    //     } else {
    //       player.pause()
    //     }
    //   } else if (event.key === 'ArrowRight') { // Skip forward
    //     event.preventDefault()
    //     player.currentTime(player.currentTime() + 5) // Skip forward by 5 seconds
    //   } else if (event.key === 'ArrowLeft') { // Skip backward
    //     event.preventDefault()
    //     player.currentTime(player.currentTime() - 5) // Skip backward by 5 seconds
    //   }
    // }

    // document.addEventListener('keydown', handleKeyDown)

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
      }
      // document.removeEventListener('keydown', handleKeyDown)
    }
  }, [src])

  // return <video ref={videoRef} className="video-js vjs-theme-forest" />
  return <video ref={videoRef} className={`video-js vjs-theme-${theme}`} poster={poster} />
  // return <video ref={videoRef} className="video-js vjs-sublime-skin" />
}


const NinetyOrDiePage = () => (
    <div className=" NinetyOrDiePage" >
      {/*<HLSVideoPlayer src="https://dzastr-public.s3.us-west-1.amazonaws.com/dzastr-horror-movie/dzastr-horror-movie.m3u8" />*/}
      {/*<video controls src="https://dzastr-public.s3.us-west-1.amazonaws.com/dzastr-horror-movie.mp4" style={{zIndex: 0}} />*/}
      <VideoPlayer  src="https://dzastr-public.s3.us-west-1.amazonaws.com/dzastr-horror-movie.mp4" />

    </div>
)

NinetyOrDiePage.propTypes = {
}

export default NinetyOrDiePage
