import React, { useEffect } from 'react'

const withScrollTop = (Component) => (props) => {
  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [])

  return <Component {...props} />
}

export default withScrollTop
